import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

export default function HealthInfo() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "#fff" }}
    >
      <Container
        className="anchor"
        id="services"
        sx={{
          mt: 10,
          mb: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src="/assets/svg/pulse.svg"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -60,
            left: 10,
          }}
        />

        <Box
          sx={{
            mt: 8,
            mb: 8,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "16px", sm: "16px", md: "20px" },
              maxWidth: { xs: "80%", sm: "80%", md: "60%" },
              opacity: "100%",
              letterSpacing: 0,
            }}
          >
            According to the CDC, truck drivers have 2x the risk of becoming
            obese– inviting heart disease, diabetes, hypertension, high
            cholesterol— problems that can, and WILL eventually cause you to
            lose your CDL.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
