import * as React from "react";

import Box from "@mui/material/Box";

export default function AboutPerson({ img, text }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "flex-start",
        width: 1,
      }}
    >
      <Box
        component="img"
        src={img}
        sx={{
          width: "148px",
          height: "148px",
          mx: 4,
          my: 4,
          objectFit: "fill",
        }}
      />
      {text}
    </Box>
  );
}
