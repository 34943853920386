import * as React from "react";
import AppFooter from "./views/AppFooter";
import ProductHero from "./views/ProductHero";
import HealthInfo from "./views/HealthInfo";
import Product from "./views/Product";
import About from "./views/About";
import ProductCTA from "./views/ProductCTA";
import Nav from "./views/Nav";
import withRoot from "./withRoot";

function App() {
  return (
    <>
      <Nav />
      <ProductHero />
      <HealthInfo />
      <Product />
      <About />
      <ProductCTA />
      <AppFooter />
    </>
  );
}

export default withRoot(App);
