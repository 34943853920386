import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { Box } from "@mui/material";
import flag from "./../images/flag.svg";

const backgroundImage = "/assets/road10.jpg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#042140", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />

      {/* VETERAN OWNED BLURB */}
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          left: "24px",
          display: "flex",
          flex: 10,
          alignItems: "center",
          justifyContent: "center",
          px: "8px",
          py: "4px",
          backgroundColor: "#2c577f",
          borderRadius: "3px",
          // mx: "auto",
          // color: "#E6E7E9",
        }}
      >
        <Box
          component="img"
          src={flag}
          alt="American Flag"
          sx={{ height: 17, width: 26, mr: 1 }}
        />
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0.3,
            opacity: 0.85,
          }}
        >
          VETERAN OWNED
        </Typography>
      </Box>

      <Typography
        color="inherit"
        align="center"
        variant="h2"
        marked="center"
        sx={{ opacity: "90%" }}
      >
        THE EXTRA MILE
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="body1"
        sx={{ mb: 2, mt: { xs: 4, sm: 6 }, fontSize: "18px" }}
      >
        Find out how we're helping truckers stay in business
      </Typography>
      <Button
        variant="contained"
        size="large"
        component="a"
        href="/#products"
        sx={{ my: 2, minWidth: 200, backgroundColor: "#1D2E7E" }}
      >
        LEARN MORE
      </Button>
      <Typography
        variant="body2"
        color="inherit"
        sx={{ mt: 1, textAlign: "center" }}
      >
        Built by a trucker,{" "}
        <span style={{ fontWeight: "bold", fontStyle: "italic" }}>for</span>{" "}
        truckers.
      </Typography>
    </ProductHeroLayout>
  );
}
