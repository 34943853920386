// import * as React from "react";
import React, { useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

// import { StlViewer } from "react-stl-file-viewer";
// import { STLViewer } from "react-stl-obj-viewer";
// import { StlViewer } from "react-stl-viewer";

// import closedImg from "./../images/closed.jpg";
// import openImg from "./../images/open.jpg";
// import riderImg from "./../images/rider.jpg";

const sxType = {
  fontSize: { xs: "14px", sm: "16px", md: "18px" },
  fontWeight: 300,
  textAlign: { xs: "left", sm: "left", md: "center" },
  lineHeight: "24px",
  color: "#fff",
  maxWidth: "69%",
};

const sxTypeHeader = {
  mt: { xs: 2, sm: 1, md: 0.5 },
  mb: 0.5,
  fontSize: { xs: "16px", sm: "18px", md: "20px" },
  fontWeight: 400,
  textAlign: { xs: "left", sm: "left", md: "center" },
  color: "#fff",
  opacity: "85%",
  letterSpacing: 1,
};

const sxTextBox = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#1F1E1E",
  width: { xs: "100%", sm: "100%", md: "50%" },
  py: 1,
};

export default function Product() {
  // const [fileLoaded, setFileLoaded] = useState(true);
  return (
    <Box
      id="products"
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "#282828" }}
    >
      <Container
        className="anchor"
        sx={{
          mt: 8,
          pb: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          variant="h4"
          marked="center"
          component="h2"
          sx={{ mt: 8, mb: 8, color: "#fff" }}
        >
          Product
        </Typography>

        <Typography
          sx={{
            mb: 10,
            fontSize: { xs: "14px", sm: "16px", md: "18px" },
            fontWeight: 300,
            textAlign: { xs: "left", sm: "left", md: "center" },
            lineHeight: "23px",
            color: "#fff",
            maxWidth: { xs: "100%", sm: "100%", md: "70%" },
          }}
        >
          <span style={{ fontWeight: 500 }}>THE EXTRA MILE</span> (TEM) is
          cardiovascular equipment designed for in-cab use by commercial
          drivers. It's a passenger seat that converts into a stationary bike
          for drivers to use while waiting to load, unload, during their break
          or any time the vehicle is parked.
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexFlow: "row wrap", width: 1 }}>
            <Box sx={sxTextBox}>
              <Typography sx={sxTypeHeader}>CONVENIENT</Typography>
              <Typography sx={sxType}>
                TEM expands into a stationary bike when you need it, and back
                into your passenger seat when you hit the road
              </Typography>
            </Box>
            <Box
              component="img"
              src="/assets/truck8.jpg"
              sx={{
                width: { xs: "100%", sm: "100%", md: "50%" },
                objectFit: "cover",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", flexFlow: "row wrap", width: 1 }}>
            <Box
              component="img"
              src="/assets/truck6.jpg"
              sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}
            />
            <Box sx={sxTextBox}>
              <Typography sx={sxTypeHeader}>COMFORTABLE</Typography>
              <Typography sx={sxType}>
                TEM allows you to work out anywhere, anytime, from the comfort
                of your cab
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexFlow: "row wrap", width: 1 }}>
            <Box sx={sxTextBox}>
              <Typography sx={sxTypeHeader}>EFFECTIVE</Typography>
              <Typography sx={sxType}>
                Cardiovascular exercise can neutralize and even{" "}
                <span style={{ fontStyle: "italic" }}>eliminate</span> most
                trucker’s health problems.{" "}
                <span style={{ fontWeight: 600 }}>
                  Even 15 minutes/day will make a difference!
                </span>
              </Typography>
            </Box>
            <Box
              component="img"
              src="/assets/truck1.jpg"
              sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}
            />
          </Box>
          {/* <Box sx={{ display: "flex", width: 1 }}>
            <StlViewer
              url={"/assets/cad.STL"}
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "90vh",
                backgroundColor: "#EAEAEA",
              }}
              modelProps={{
                color: "#B92C2C",
                scale: 0.5,
                positionX: 0,
                positionY: 0,
                rotationX: 0,
                rotationY: 0,
                gridWidth: 500,
                gridLength: 800,
              }}
              // modelColor="#B92C2C"
              // backgroundColor="#EAEAEA"
              // rotate={true}
              showAxes
              orbitControls
              shadows
              onFinishLoading={(ev) =>
                console.log("STL EV ON FINISHED LOADING", ev)
              }
              onError={(e) => console.log(e, "STL ERROR")}
            />
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
}
