import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import AboutPerson from "../components/AboutPerson";
import bradImg from "./../headshots/brad_headshot.jpg";
import joeImg from "./../headshots/joey.jpg";

const bradText = (
  <Typography
    variant="body1"
    align="left"
    sx={{ mx: 3, my: 3, color: "#fff", maxWidth: "700px" }}
  >
    I'm Brad — the founder of{" "}
    <span style={{ fontWeight: 600 }}>Overhaul Me Inc.</span> and creator of{" "}
    <span style={{ fontWeight: 600 }}>THE EXTRA MILE</span>. I first designed
    <span style={{ fontWeight: 600 }}> TEM</span> in 2006, after my doctor
    expressed concern regarding my blood pressure and cholesterol levels. He did
    what most doctors do — he recommended a pill. That wasn't a route I wanted
    to take.
    <br />
    <br />I knew that I could solve this problem through diet and exercise, but
    it would be difficult given the amount of time I'm on the road. I needed a
    way to get an effective workout from within my truck.
    <br />
    <br />
    <span style={{ fontWeight: 600 }}>THE EXTRA MILE</span> has transformed me
    into someone who doesn’t feel tired most of the day, someone who receives
    annual blood test results in the normal range, and someone who is able to be
    recertified consistently every 2 years!
  </Typography>
);

const joeText = (
  <Typography
    variant="body1"
    align="left"
    sx={{ mx: 3, my: 3, color: "#fff", maxWidth: "700px" }}
  >
    My name is Joe (Brad's younger brother) — I'm the co-founder of{" "}
    <span style={{ fontWeight: 600 }}>Overhaul Me Inc.</span> and I've worked
    countless hours on prototype models of{" "}
    <span style={{ fontWeight: 600 }}>TEM</span>. My brother and I have spent
    over 10 years designing, researching, and building prototypes to bring
    <span style={{ fontWeight: 600 }}> THE EXTRA MILE</span> to what it is
    today.
    <br />
    <br />
    Over a decade ago, my brother approached me about his health concerns. Since
    then, we've designed and built three prototypes of{" "}
    <span style={{ fontWeight: 600 }}>TEM</span> for Brad to use daily. I have
    seen how The Extra Mile has changed his life and would love to see it
    available to everyone!
  </Typography>
);

const bgcolor = "#1F2021";
// const bgcolor = "#052432";

export default function About() {
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        overflow: "hidden",
        backgroundColor: bgcolor,
        background:
          "linear-gradient(242.58deg, rgba(31, 32, 33, 0.8) 0%, #1F2021 62.59%)",
        pt: 4,
        mt: 0,
        mb: 10,
        width: 1,
        height: 1,
      }}
      className="anchor"
      id="about"
    >
      <Container
        sx={{
          mt: 0,
          mb: 10,
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          marked="center"
          component="h2"
          sx={{ mt: 8, mb: 4, opacity: 0.9, color: "#fff" }}
        >
          About
        </Typography>
        <AboutPerson img={bradImg} text={bradText} />
        <AboutPerson img={joeImg} text={joeText} />
      </Container>
    </Box>
  );
}
