import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import { Typography } from "@mui/material";

const rightLink = {
  fontSize: 16,
  color: "common.white",
  mx: 1,
  letterSpacing: 1,
  fontWeight: 400,
};

export default function Nav() {
  const [mobileNav, setMobileNav] = useState(false);
  const [isMenuActive, toggleMenu] = useState(false);

  const checkWidth = () => {
    setMobileNav(window.innerWidth <= 850);
  };

  useEffect(() => {
    setMobileNav(window.innerWidth <= 850);
    window.addEventListener("resize", checkWidth);
  }, []);

  return (
    <div>
      <AppBar position="fixed" sx={{ backgroundColor: "#031529" }}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="#"
            sx={{
              fontSize: 18,
              display: "flex",
              alignItems: "center",
              color: "#E6E7E9",
              mr: 1,
            }}
          >
            <Box
              component="img"
              src="/assets/svg/logo.svg"
              alt="Extra Mile logo"
              sx={{ height: 38, width: 38, mr: 3 }}
            />
            <div
              style={{
                fontSize: 18,
                flexFlow: "column nowrap",
                flexDirection: "column",
                textAlign: "center",
                color: "#E6E7E9",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "18px" },
                  fontWeight: 600,
                  letterSpacing: 1,
                }}
              >
                THE EXTRA MILE
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "12px" },
                  fontWeight: 400,
                  opacity: "88%",
                  letterSpacing: 1.5,
                }}
              >
                By OVERHAUL ME INC
              </Typography>
            </div>
          </Link>
          <Box sx={{ flex: 1 }} />

          {mobileNav && (
            <Box
              component="img"
              src="/assets/svg/hamburger-menu.svg"
              onClick={() => toggleMenu(!isMenuActive)}
              alt="menu"
              sx={{
                width: "42px",
                height: "42px",
              }}
            />
          )}

          <Box
            sx={
              mobileNav
                ? isMenuActive
                  ? {
                      position: "absolute",
                      bgcolor: "#00060f",
                      display: "flex",
                      flexDirection: "column",
                      height: "200px",
                      justifyContent: "space-around",
                      top: 64,
                      left: 0,
                      right: 0,
                    }
                  : { display: "none" }
                : {
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    maxWidth: "320px",
                  }
            }
          >
            <Link
              variant="h6"
              underline="none"
              href="/#products"
              onClick={() => toggleMenu(false)}
              sx={{ ...rightLink }}
            >
              {"product"}
            </Link>
            <Link
              variant="h6"
              underline="none"
              href="/#about"
              onClick={() => toggleMenu(false)}
              sx={{ ...rightLink }}
            >
              {"About"}
            </Link>
            <Link
              variant="h6"
              underline="none"
              href="/#quote"
              onClick={() => toggleMenu(false)}
              sx={{ ...rightLink, color: "#04A5F5" }}
            >
              {"Contact"}
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}
